import React from "react"
import FuboBanner from "src/components/common/FuboBanner"
import { StyledSectionContainer } from "src/components/common/StyledSectionContainer"
import styled from "styled-components"

const ChannelsSection = () => {
  const list = [
    {
      name: "Roku Channel",
      image:
        "https://assets-imgx.fubo.tv/marketing/lp/images/fsn/channels/roku.jpg?ch=width&auto=format,compress",
    },
    {
      name: "Samsung TV Plus",
      image:
        "https://assets-imgx.fubo.tv/marketing/lp/images/fsn/channels/samsung.jpg?ch=width&auto=format,compress",
    },
    {
      name: "Tubi",
      image:
        "https://assets-imgx.fubo.tv/marketing/lp/images/fsn/channels/tubi.jpg?ch=width&auto=format,compress",
    },
    {
      name: "Tablo TV",
      image:
        "https://assets-imgx.fubo.tv/marketing/lp/images/fsn/channels/tablo.jpg?ch=width&auto=format,compress",
    },
    {
      name: "Plex",
      image:
        "https://assets-imgx.fubo.tv/marketing/lp/images/fsn/channels/plex.jpg?ch=width&auto=format,compress",
    },
    {
      name: "Freevee",
      image:
        "https://assets-imgx.fubo.tv/marketing/lp/images/fsn/channels/freevee.jpg?ch=width&auto=format,compress",
    },
    {
      name: "LG Channels",
      image:
        "https://assets-imgx.fubo.tv/marketing/lp/images/fsn/channels/lg.jpg?ch=width&auto=format,compress",
    },

    {
      name: "Vizeo Watch Free+",
      image:
        "https://assets-imgx.fubo.tv/marketing/lp/images/fsn/channels/vizio.jpg?ch=width&auto=format,compress",
    },
  ]

  return (
    <StyledChannelsSection>
      <h2>Fubo Sports is Available On</h2>
      <div className="channels-section">
        {list.map((item, index) => (
          <div className="image" key={index}>
            <img src={item.image} alt={item.name} />
          </div>
        ))}
      </div>
      <FuboBanner />
    </StyledChannelsSection>
  )
}

export default ChannelsSection

const StyledChannelsSection = styled(StyledSectionContainer)`
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 767px) {
    padding-left: 10px;
    padding-right: 10px;
  }
  .channels-section {
    color: rgb(36, 36, 36);
    max-width: 625px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    .image {
      width: 100%;
      border-radius: 11px;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
  }
`
