import React from "react"
import Layout from "src/components/common/Layout"
import HeroSection from "src/components/page/watch/HeroSection"
import ChannelsSection from "src/components/common/ChannelsSection"
import ScheduleCarouselSection from "src/components/page/watch/ScheduleCarouselSection"
import ShowsSection from "src/components/page/watch/ShowsSection"
import YoutubeSection from "src/components/page/watch/YoutubeSection"
import ProgrammingSection from "src/components/page/watch/ProgrammingSection"

const WatchPage = () => {
  return (
    <Layout>
      <HeroSection />
      <ScheduleCarouselSection />
      <ChannelsSection />
      <ShowsSection />
      <YoutubeSection />
      <ProgrammingSection />
    </Layout>
  )
}

export default WatchPage
