import { Link } from "gatsby"
import React from "react"
import { StyledSectionContainer } from "src/components/common/StyledSectionContainer"
import styled from "styled-components"

const list = [
  {
    name: "No Chill with Gilbert Arenas",
    image:
      "https://assets-imgx.fubo.tv/marketing/lp/images/fsn/shows/no-chill-vertical.jpg?ch=width&auto=format,compress",
  },
  {
    name: "Young Person Basketball Podcast with R.J. Hampton",
    image:
      "https://assets-imgx.fubo.tv/marketing/lp/images/fsn/shows/young-person-vertical.jpg?ch=width&auto=format,compress",
  },
  {
    name: "Airing It Out With Housh & Scandrick",
    image:
      "https://assets-imgx.fubo.tv/marketing/lp/images/fsn/shows/airing-it-out-vertical.jpg?ch=width&auto=format,compress",
  },
  {
    name: "Getcha Popcorn Ready With T.O. & Hatch",
    image:
      "https://assets-imgx.fubo.tv/marketing/lp/images/fsn/shows/getcha-popcorn-ready-vertical.jpg?ch=width&auto=format,compress",
  },
]

const ShowsSection = () => {
  return (
    <StyledShowsSection>
      <h2>
        <span>
          <img
            src="https://assets-imgx.fubo.tv/marketing/lp/images/fsn/fsn-logo-white.png?w=300&ch=width&auto=format,compress"
            alt="Fubo Sports Logo"
          />
        </span>{" "}
        Original Shows
      </h2>
      <div className="shows-section">
        {list.map((item, index) => (
          <Link className="image" to="/series" key={index}>
            <img src={item.image} alt={item.name} />
          </Link>
        ))}
      </div>
      <p>
        We’re bringing game changers to the world of sports TV. Watch our
        outstanding lineup of talent every day on Fubo Sports.
      </p>
    </StyledShowsSection>
  )
}

export default ShowsSection

const StyledShowsSection = styled(StyledSectionContainer)`
  background: #000;
  color: #fff;
  position: relative;
  padding-left: 60px;
  padding-right: 60px;
  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  &::before {
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    background: radial-gradient(#fff, transparent 70%);
    opacity: 0.35;
  }
  > * {
    z-index: 1;
  }
  h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    span {
      img {
        display: inline-block;
        width: 60px;
      }
    }
  }
  .shows-section {
    max-width: 1200px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    .image {
      width: 100%;
      border-radius: 10px;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    @media (max-width: 600px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  p {
    color: #a7aaaa;
  }
`
