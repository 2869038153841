import React, { useEffect, useState } from "react"
import styled from "styled-components"

import VideoPlayer from "src/components/common/VideoPlayer"

import {
  liveStreamSrc,
  liveStreamType,
  springServeVast,
} from "src/modules/constants"

import FSNCanadaSlate from "/src/assets/images/FSN_Canada_Slate.jpg"
import GlobalSlate from "/src/assets/images/Global_Slate.jpg"

const videoJSOptions = {
  autoplay: true,
  controls: true,
  fill: true,
  liveui: true,
  responsive: true,

  sources: [
    {
      src: liveStreamSrc,
      type: liveStreamType,
    },
  ],
}

const imaOptions = {
  adTagUrl: springServeVast,
  showCountdown: true,
  showControlsForJSAds: true,
  adsRenderingSettings: {
    uiElements: ["countdown", "adAttribution"],
  },
}

// const settings = {
//   dots: false,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 3,
//   slidesToScroll: 1,
//   variableWidth: true,
//   responsive: [
//     {
//       breakpoint: 600,
//       settings: {
//         arrows: false,
//         centerMode: true,
//         slidesToShow: 2,
//       },
//     },
//   ],
// }

const HeroSection = () => {
  // Check geolocation for correct stream
  const [currentCountry, setCurrentCountry] = useState("")

  useEffect(() => {
    if (window && window.location.search.includes("spoofgeo")) {
      const parameters = new URLSearchParams(window.location.search)
      setCurrentCountry(parameters.get("spoofgeo"))
    } else {
      const locationApi = "https://dev-api.fubo.tv/v3/location"
      fetch(locationApi, {
        method: "GET",
      })
        .then(res => res.json())
        .then(response => {
          setCurrentCountry(response.country_code2)
          if (window)
            window["fuboLocationData"] = {
              country_code: response.country_code2,
              country_name: response.country_name,
            }
        })
        .catch(error => console.log(error))
    }
  }, [])

  return (
    <StyledHeroSection>
      {/* <div className="ad-banner">
        <picture>
          <source
            media="(max-width: 600px)"
            srcset="https://assets-imgx.fubo.tv/marketing/lp/images/fsn/banners/watch-lxf-12-728x90.jpg?ch=width&auto=format,compress"
          />
          <source srcset="https://assets-imgx.fubo.tv/marketing/lp/images/fsn/banners/watch-lxf-12.jpg?ch=width&auto=format,compress" />
          <img
            src="https://assets-imgx.fubo.tv/marketing/lp/images/fsn/banners/watch-lxf-12.jpg?ch=width&auto=format,compress"
            alt="Watch LXF 12 live November 18th on Fubo Sports"
          />
        </picture>
      </div> */}
      <HeaderWrapper>
        <h1 className="hero__title">Watch Fubo Sports live</h1>
        <h3 className="hero__subtitle">
          Live games, original shows, your favorite sports movies & more.{" "}
          <span className="hero__subtitle--orange">All free.</span>
        </h3>
      </HeaderWrapper>
      <VideoWrapper>
        {currentCountry === "US" || currentCountry === "USA" ? (
          <VideoPlayer {...videoJSOptions} imaOptions={imaOptions} />
        ) : currentCountry === "CA" || currentCountry === "CAN" ? (
          <VideoSlate>
            <img src={FSNCanadaSlate} alt="Sign up to watch at fubo.tv" />
          </VideoSlate>
        ) : (
          currentCountry !== "" && (
            <VideoSlate>
              <img
                src={GlobalSlate}
                alt="This Content is Unavailable. You are outside of the region where this is available."
              />
            </VideoSlate>
          )
        )}
      </VideoWrapper>
    </StyledHeroSection>
  )
}

export default HeroSection

const StyledHeroSection = styled.section`
  position: relative;
  padding: 50px 0;
  color: #fff;

  @media (max-width: 767px) {
    padding: 25px 20px;
  }

  .ad-banner {
    padding: 0 2%;
    margin: 0 auto 50px;
    max-width: 1200px;

    @media (max-width: 920px) {
      padding: 0;
      margin: 0 auto 20px;
    }

    img {
      width: 100%;
    }
  }

  .hero__title {
    font-weight: 800;
    letter-spacing: 0px;
    line-height: 1.2;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 0;
    color: #fff;
    @media (max-width: 767px) {
      font-size: 2em;
    }
  }

  .hero__subtitle {
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
    &--orange {
      color: rgba(255, 58, 3, 1);
      display: inline-block;
    }
    @media (max-width: 767px) {
      font-size: 1.1em;
    }
  }
`

const HeaderWrapper = styled.div`
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 900px;
        margin: 0 auto;

        @media (min-width: 1301px) {
            max-width: 1100px;
        }
    }
`

const VideoSlate = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;

  @media (max-width: 1300px) {
    max-width: 900px;
  }
  div.blank-slate {
    width: 100%;
    height: 0;
    padding-bottom: calc(100% * 9 / 16);
  }
  img {
    width: 100%;
  }
`

const VideoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;

  @media (max-width: 900px) {
    margin-top: 1em;
  }
`
